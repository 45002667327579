//--------------------------------------------------
// HOME PAGE
//--------------------------------------------------
/* -- GENERAL -- */
.home-page .rg-twitter-recent-campaigns__wrapper {
    .column {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }
}

.more-recent-updates__wrapper {
    padding-top: 0;
    padding-bottom: 1.5rem;

    @media only screen and (min-width : 768px) {
        padding-bottom: 3rem;
    }
}

.recent-updates__wrapper2 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    .page-section__title {
        &:after {
            display: none;
            content: '';
            background-color: white;
            height: 3px;
            width: 1.5rem;
            position: absolute;
            bottom: -2px;
            left: 50%;
            right: auto;
            transform: translate(-50%, 0);

            @media only screen and (min-width : 1000px) {
                display: block;
            }
        }
    }

    @media only screen and (max-width : 1000px) {
        .column:first-child {
            padding-bottom: 0;

            .news__article-excerpt:last-child {
                border-bottom: 2px solid $white;
            }
        }

        .column:last-child {
            padding-top: 0;
        }
    }
}

.wrapper__w-border {
    box-shadow: 0 1px 2px rgba(10, 10, 10, 0), 0 0 0 1px rgba(10, 10, 10, 0.1);

    .page-section__title {
        margin-bottom: 2rem;
    }
}

.section-padding__controller--top {
    padding-top: 1rem;

    @media only screen and (min-width : 768px) {
        padding-top: 1.5rem;
    }
}

.section-padding__controller--bottom {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

/* -- TIMEZONES -- */
.section__tz {
    padding: 1rem 1.5rem;
    background-color: $white !important;

    @media only screen and (min-width : 390px) {
        padding-top: 1.75rem;
    }

    @media only screen and (min-width : 768px) {
        padding-top: 1rem;
    }
}

.timezone {    
    p {
        @media only screen and (min-width : 768px) {
            display: inline-block;

            &:first-child {
                margin-right: .5rem;
            }
        }
    }
}

/* -- RG -- */
.section__rg {
    position: relative;
    padding: 0;
    @media only screen and (min-width : 768px) {
        background-color: transparentize($grey-light,0.7) !important;
        background-image: repeating-linear-gradient(45deg, transparent, transparent 1px, rgba(255,255,255,.1) 1px, rgba(255,255,255,.1) 5px);
    }
}

.rg__item {
    position: relative;
    display: block;

    .rg-gradient {
        display: none;

        @media only screen and (min-width : 768px) {
            // display: block;
            width: 25%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: transparentize($new-light-blue,0.35);
            pointer-events: none;
            z-index: 1;

            &:after {
                content: '';
                // display: block;
                height: 0;
                width: 0;
                border-top: 100vw solid transparent;
                border-left: 50vw solid transparentize($new-light-blue,0.35);
                position: absolute;
                top: 0;
                left: 100%;
            }
        }
    }

    .image {
        overflow: hidden;
    }

    .image__img-item {
        height: 200px;
        background-size: cover;
    }

    @media only screen and (min-width : 768px) {
        .image__img-item {
            height: 500px;
        }
    }

    .image__img-item {
        @include transition(transform .25s cubic-bezier(0.39, 0.575, 0.565, 1));
    }
}

.rg__item__title {
    text-align: left;
    height: auto;
    position: static;
    padding: 1rem 1.5rem;
    background-color: $white-ter;

    @media only screen and (min-width : 768px) {
        background: transparentize($new-light-blue,0.5);
        width: 22rem;
        position: absolute;
        top: auto;
        bottom: 2.5rem;
        z-index: 1;
        left: 1.5rem;
    }

    @media only screen and (min-width : 1000px) {
        width: 25rem;
    }

    @media only screen and (min-width : 1192px) {
        width: 28rem;
    }
}

.content h2.rg__item__title {
    letter-spacing: -0.25px;
    margin: 0;
    font-size: 1.5em;

    @media only screen and (min-width : 768px) {
        color: $white;
        text-shadow: 0 1px 2px rgba(10, 10, 10, 0.9);
    }
}

.rg__wrapper {
    .owl-dots {
        position: absolute;
        left: 1.5rem;
        bottom: 9rem;

        @media only screen and (min-width : 768px) {
            left: 2.5rem;
            bottom: 1rem;
        }

        .owl-dot span {
            background: transparent;
            border: 1px solid $white;
            box-shadow: 0 1px 2px rgba(10, 10, 10, 0), 0 0 0 1px rgba(10, 10, 10, 0.1);
        }

        .owl-dot.active span,
        .owl-dot:hover span {
            background: $white;
        }
    }
    // .owl-nav {
    //     position: absolute;
    //     top: 268px;
    //     right: 0;
    //     margin-top: 0;

    //     @media only screen and (min-width : 768px) {
    //         top: 368px;
    //     }
    // }

    // &.owl-theme .owl-nav [class*='owl-'] {
    //     margin: 0;
    //     @include border-radius(0);
    //     background-color: $white;
    //     color: $grey;
    // }
}

.rg-right__media {
    padding-left: 0.75rem;
    padding-right: 0.75rem;

    @media only screen and (min-width : 768px) {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    @media only screen and (min-width : 1000px) {
        position: absolute;
        top: 349px;
        right: 0;
        z-index: 10;
        padding-top: initial;
        padding-bottom: initial;
        padding-left: initial;
        padding-right: initial;
        width: 33%;
    }

    @media only screen and (min-width : 1120px) {
        // width: 320px;
    }

    @media only screen and (min-width : 1216px) {
        // width: 355px;
    }
}

/* -- CURRENT CAMPAIGNS -- */
.current-campaigns__wrapper {
    margin-bottom: 1.25rem;

    @media only screen and (min-width : 768px) {
        margin-bottom: 12px;
    }

    .image {
        margin: 0 auto;
    }

    .subtitle {
        margin-top: 1rem;
    }
}

/* -- TWITTER TIMELINE -- */
.twitter-timeline__wrapper {
    height: 782px;
    overflow-y: auto;
    border: 1px solid $grey-lighter;
}

.twitter-feed__wrapper {    
    @media only screen and (min-width : 768px) {
        padding: 1.5rem 0 0.75rem;
    }
}

.twitter__container__wrapper {
    padding: 0.75rem;
}

/* -- RECENT UPDATES & NEWS -- */
@media only screen and (min-width : 768px) {
    .recent-updates__wrapper {
        .column.is-6:first-child {
            padding-left: 0.75rem;
        }

        .column.is-6:last-child {
            padding-right: 0.75rem;
        }
    }
}

.more-recent-updates,
.news-listing__wrapper {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.more-recent-updates {
    background-color: $white;
    padding-bottom: 4.5rem;
}

/* -- LATEST GALLERIES -- */
.homepage__photo__gallery__wrapper {
    .card .card-image:after {
        content: 'OPEN GALLERY';
    }
}