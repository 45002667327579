//--------------------------------------------------
// STATEMENTS
//--------------------------------------------------
/* -- GENERAL -- */

/* -- STATEMENT SEARCH -- */
.statement-search-results__wrapper {
    background-color: $white-ter;
}

.statement-search__field {
    width: 75%;
}