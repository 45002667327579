//--------------------------------------------------
// TYPOGRAPHY
//--------------------------------------------------
/* -- BODY COPY -- */
.small {
    font-size: 90%;
}

/* -- HEADINGS -- */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $family-sans-serif;
    font-weight: $family-font-weight-bold;
}

h1,
h2 {
    letter-spacing: -.4px;
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.5rem;
}

h4 {
    font-size: 1.25rem;
}

h5 {
    font-size: 1rem;
}

.content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $family-sans-serif;
        font-weight: $family-font-weight-bold;
    }

    h1,
    h2 {
        letter-spacing: -.4px;
    }

    h4 {
        line-height: 1.2;
    }

    h6 {
        line-height: 1.25;
    }
}

/* -- LIST STYLES -- */
.list-unstyled > li {
    list-style: none;
}

.content ul.list-unstyled {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    list-style: none;
}

.list-inline > li {
    display: inline-block;
    padding-right: 2px;
    padding-left: 2px;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }
}

/* -- BLOCKQUOTES -- */
.content blockquote {
    background-color: transparentize($success,0.9);
    font-weight: $family-font-weight-bold;
    padding: 1.25em 1.5em 1.25em 1.5em;
    border-left-color: $new-light-brown;
}