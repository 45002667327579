//--------------------------------------------------
// TAGS
//--------------------------------------------------
/* -- GENERAL -- */
.tag {
    @include border-radius(3px);
    @include transition(all .2s cubic-bezier(0.39, 0.575, 0.44, 1));
    font-family: $secondary-font-family;

    &.is-success {
        border: 1px solid $success;
        background-color: transparent;
        color: $success;
    }
}

.tag.is-success:hover {
    @media only screen and (min-width : 768px) {
        color: $white;
        background-color: $success;
    }
}

.topics-listing__wrapper {
    li {
        margin-bottom: 0.75rem;
    }
}

// Button tag
.button__tag {
    margin-left: 0.25rem;
    padding: 0 0.25rem;
    color: $white;
    border-color: transparent;
    background-color: transparent;
}

.button.is-outlined .button__tag {
    color: $success;
    border-color: transparent;
}

.button.is-outlined:hover .button__tag {
    color: $white;
    border-color: transparent;
}