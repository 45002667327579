/*
 * Custom CSS created by yoyolabs.
 * http://yoyolabs.yeystudio.com
 *
 */

@media screen {
    //--------------------------------------------------
    // IMPORTS
    //--------------------------------------------------
    // Helpers
    @import "helpers/variables.scss";
    @import "node_modules/bulma/bulma.sass";
    @import "helpers/mixins.scss";

    // Base
    @import "base/typography.scss";
    @import "base/links.scss";
    @import "base/customisations.scss";
    @import "helpers/helpers.scss";

    // Add-ons
    @import "addons/animations.scss";
    @import "helpers/custom-icons.scss";

    // Components
    @import "components/accordian.scss";
    @import "components/alerts.scss";
    @import "components/box-card-article.scss";
    @import "components/buttons.scss";
    @import "components/carousal.scss";
    @import "components/forms.scss";
    @import "components/modals.scss";
    @import "components/nav.scss";
    @import "components/pagination.scss";
    @import "components/search.scss";
    @import "components/tables.scss";
    @import "components/tags.scss";

    // Layout
    @import "layout/scaffolding.scss";
    @import "layout/header.scss";
    @import "layout/footer.scss";

    // Pages
    @import "pages/page.scss";
    @import "pages/homepage.scss";
    @import "pages/ppl-listing.scss";
    @import "pages/pr.scss";
    @import "pages/statements.scss";
}

@media print {
    // Print
    @import "pages/print.scss";
}