//--------------------------------------------------
// LINKS
//--------------------------------------------------
.page-content {
    .content,
    .message-body {
        a {
            background-image: linear-gradient(to right, $grey-light 25%, rgba(255, 255, 255, 0) 0%);
            background-position: bottom;
            background-size: 8px 1px;
            background-repeat: repeat-x;
            text-decoration: none;
            color: $success;
            @extend %link-animation;
        }

        a:hover {
            color: $primary;
        }
    }
}