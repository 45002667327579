//--------------------------------------------------
// PAGINATION
//--------------------------------------------------
/* -- GENERAL -- */
.pagination {
    margin-top: 1rem;
}

.pagination-link,
.pagination-next,
.pagination-previous {
    font-family: $secondary-font-family;
    font-size: 90%;
    color: $success;

    &:hover {
        color: $success;
        border-color: $success;
    }
}

.pagination-link.is-current {
    background-color: $success;
    border-color: $success;

    &:hover {
        color: $white;
    }
}