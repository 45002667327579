//--------------------------------------------------
// MIXINS
//--------------------------------------------------
// Links
@mixin link($link-colour, $link-hover-colour, $border-hover-colour) {
    color: $link-colour;
    border-bottom: 1px solid transparent;
    @extend %link-animation;
    
    &:visited {
        color: $link-colour;
        border-bottom: 1px solid transparent;
        @extend %link-animation;
    }

    &:hover,
    &:focus,
    &:active,
    &.is-active {
        color: $link-hover-colour;
        border-bottom: 1px solid $border-hover-colour;
    }
}

// Center vertically and/or horizontally an absolute positioned element
@mixin contentcenter($horizontal: true, $vertical: true) {
    position: absolute;
    @if ($horizontal and $vertical) {
        top: 50%;
        left: 50%;
        bottom: auto;
        right: auto;
        transform: translate(-50%, -50%);
    }
    @else if ($horizontal) {
        left: 50%;
        right: auto;
        transform: translate(-50%, 0);
    }
    @else if ($vertical) {
        top: 50%;
        bottom: auto;
        transform: translate(0, -50%);
    }
}

// Antialiasing mode font rendering
@mixin font-smoothing {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Border radius
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

// Transition
@mixin transition($transition...) {
    -webkit-transition: $transition;
       -moz-transition: $transition;
         -o-transition: $transition;
            transition: $transition;
}

// Opacity
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}

// Background size cover
@mixin background-cover {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

// Button variant
@mixin button-variant($color, $background-color, $border-radius, $border-color, $border-width, $hover-color, $hover-bg-color, $hover-border-color, $active-bg-color) {
    color: $color;
    background-color: $background-color;
    border-color: $border-color;
    border-width: $border-width;
    @include border-radius($border-radius);
    @include transition(.3s);
    &:hover,
    &:focus,
    &.focus,
    .open > .dropdown-toggle & {
        color: $hover-color;
        background-color: $hover-bg-color;
        border-color: $hover-border-color;
    }
    &:active,
    &.active,
    &:active:focus,
    &.active:focus,
    &:active:hover,
    &.active:hover {
        background-color: $active-bg-color;
        color: $hover-color;
        border-color: $hover-border-color;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    &:active,
    &.active,
    .open > .dropdown-toggle & {
        background-image: none;
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &,
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            color: $hover-color;
            background-color: $hover-bg-color;
            border-color: $hover-border-color;
        }
    }
}