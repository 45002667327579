//--------------------------------------------------
// VARIABLES
//--------------------------------------------------
/* -- FONT LIBRARIES -- */
@import url('https://fonts.googleapis.com/css?family=Lusitana:400,700|Fira+Sans:300,400,500,700');

@font-face {
    font-family: "HK Grotesk";
    src: url("../../fonts/HKGrotesk-Light.otf");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "HK Grotesk";
    src: url("../../fonts/HKGrotesk-Bold.otf");
    font-weight: 700;
    font-style: normal;
}

/* -- FONTS -- */
// Stacks
// $family-sans-serif: "Fira Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$family-sans-serif: "Lusitana", -apple-system, BlinkMacSystemFont, "Adobe Caslon Pro", "Times", "Times New Roman", serif;
$secondary-font-family: 'HK Grotesk', "Helvetica Neue", Arial, sans-serif;

/* -- COLOURS -- */
// Brand colours
$st-pat-blue: #1E3D7F;
$pacific-blue: #17B9C6;
$picton-blue: #4CA8FF;
$space-cadet: #171F4C;
$cornell-red: #BD2917;

$new-blue: #042a54;
$new-brown: #ce8d4c;
$new-light-blue: #7b97aa;
$new-very-light-blue: #DCE1E5;

$new-green: #00a19a;
$new-orange: #e94e1b;
$new-yellow: #f9b233;
$new-light-brown: #cbbba0;
$new-dark-blue: #15222d;

// Bulma overrides
$primary: $new-blue;
$info: $new-green;
$success: $new-light-blue;
$warning: $new-dark-blue;
$danger: $new-orange;

/* -- TYPOGRAPHY -- */
// $weight-normal: 300;
$family-font-weight-bold: 700;
$secondary-font-weight-bold: 700;

/* -- CONTAINER WIDTHS -- */
$small-device: 1024px;
$medium-device: 1096px;
$large-device: 1096px;
$extra-large-device: $large-device;