//--------------------------------------------------
// FOOTER
//--------------------------------------------------
/* -- GENERAL -- */
.footer {
    padding: 0;
    background-color: $white-bis !important;
    
    p,
    a,
    a:hover,
    a:focus,
    a:active,
    a.active {
        color: $grey;
    }
}

/* -- QUICKLINKS -- */
.quick-links {
    background-color: $white-bis !important;

    .box {
        height: 200px;
        padding: 1rem;
    }

    .quick-links__image__wrapper {
        height: 100%;
        width: 100%;
    }

    .column {
        &:nth-child(4n+1) .box {
            border-top: 5px solid $info;
        }

        &:nth-child(4n+2) .box {
            border-top: 5px solid $danger;
        }

        &:nth-child(4n+3) .box {
            border-top: 5px solid $new-yellow;
        }

        &:nth-child(4n+4) .box {
            border-top: 5px solid $new-light-brown;
        }
    }
}

/* -- FOOTER TOP -- */
.footer-top {
    background-color: $black-bis !important;

    p {
        color: $grey-light;
    }
}

// Notices
.footer-top .column {
    @media only screen and (min-width : 1000px) {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.footer-top .message {
    background-color: transparent;
    margin: 1rem 0;

    .message-body {
        border-color: $grey-dark;
        color: $grey-light;
        padding: 1.25em 2em;
    }
}

/* -- FOOTER BOTTOM -- */
.footer-bottom {
    background-color: $black-ter !important;
    font-size: 90%;
}

.social-links .level-item {
    display: inline-block;
}

.footer-top {
    @media only screen and (min-width : 1000px) {
        padding-bottom: 2.5rem !important;
    }
}

.footer-top,
.footer-bottom {
    padding: 1.5rem;
}