//--------------------------------------------------
// NAVIGATION
//--------------------------------------------------
/* -- BRAND -- */
.brand {
    height: 4.5rem;

    @media only screen and (min-width : 390px) {
        height: 5rem;
    }

    @media only screen and (min-width : 480px) {
        height: 6.5rem;
    }

    @media only screen and (min-width : 768px) {
        height: 8rem;
    }

    .title {
        font-family: $secondary-font-family;
        font-size: 125%;
        font-weight: $secondary-font-weight-bold;
        letter-spacing: -.8px;
    }

    img,
    svg {
        max-width: 85%;
        text-align: left;
        margin-left: 0;

        @media only screen and (min-width : 768px) {
            max-width: 100%;
        }
    }

    .brand-mobile {
        width: 500px;
        margin-top: 8px;

        @media only screen and (min-width : 390px) {
            margin-top: auto;
        }
    }

    &.level {
        margin-bottom: 0;
    }

    .level-left {
        margin-right: auto;

        @media only screen and (min-width : 1000px) {
            margin-left: calc((100% - #{$small-device}) / 2);
        }

        @media only screen and (min-width : 1192px) {
            margin-left: calc((100% - #{$medium-device}) / 2);
        }

        @media only screen and (min-width : 1384px) {
            margin-left: calc((100% - #{$large-device}) / 2);
        }
    }

    .level-right .level-item {
        margin-right: 1.5rem;
    }

    a {
        @include link($grey-lighter, $grey-light, transparent);
    }
}

.brand h1 {
    padding: 1.5rem;
    color: $black-bis;
}

.brand {
    background-color: $primary;
}

.top-nav {
    background-color: $primary;
}

/* -- NAV TOGGLE -- */
.nav-toggle {
    cursor: pointer;
    display: block;
    height: 3.5rem;
    position: absolute;
    width: 3.5rem;
    top: .75rem;
    right: .5rem;

    @media only screen and (min-width : 480px) {
        top: 1.5rem;
    }

    &:hover {
        background-color: transparent;
    }

    @media only screen and (min-width : 768px) {
        display: none;
    }

    span {
        background-color: $white-ter;
        display: block;
        height: 1px;
        left: 50%;
        margin-left: -10px;
        position: absolute;
        top: 50%;
        transition: none 86ms ease-out;
        -webkit-transition-property: background,left,opacity,-webkit-transform;
        transition-property: background,left,opacity,-webkit-transform;
        transition-property: background,left,opacity,transform;
        transition-property: background,left,opacity,transform,-webkit-transform;
        width: 20px;

        @media only screen and (min-width : 768px) {
            background-color: $grey;
        }
    }

    &.is-active span {
        background-color: $white-ter;

        @media only screen and (min-width : 768px) {
            background-color: $grey;
        }
    }

    span:nth-child(1) {
        margin-top: -8px;
    }

    span:nth-child(2) {
        margin-top: -1px;
    }

    span:nth-child(3) {
        margin-top: 6px;
    }
}

.nav-toggle.is-active {
    span:nth-child(1) {
        margin-left: -5px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-transform-origin: left top;
        transform-origin: left top;
    }

    span:nth-child(2) {
        opacity: 0;
    }

    span:nth-child(3) {
        margin-left: -5px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
    }
}

/* -- TOP NAV -- */
#top-nav__brand {
    position: fixed;
    width: 100%;
    z-index: 100;
    top: 0;

    @media only screen and (min-width : 768px) {
        position: static;
    }
}

#top-nav.nav__section--active {
    position: fixed;
    width: 100%;
    z-index: 100;
    top: 0;

    + .section {
        margin-top: 56px;
    }
}

.menu {
    padding-left: 0;
    list-style: none;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    margin: 0 auto;
    @include transition(max-height .2s ease-in-out, opacity .2s ease-in-out);

    @media only screen and (min-width : 768px) {
        max-height: 100%;
        opacity: 1;
        overflow: visible;
        // max-width: 740px;
        // width: 740px;
    }

    @media only screen and (min-width : 1000px) {
        max-width: 960px;
        width: 960px;
    }

    @media only screen and (min-width : 1024px) {
        max-width: $small-device;
        width: $small-device;
    }

    @media only screen and (min-width : 1192px) {
        max-width: $medium-device;
        width: $medium-device;
    }

    @media only screen and (min-width : 1384px) {
        max-width: $large-device;
        width: $large-device;
    }

    > li {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
    }

    li a {
        text-decoration: none;
        color: $white-ter;
        text-decoration: none;
        @extend %link-animation;
        cursor: pointer;

        @media only screen and (min-width : 768px) {
            color: $grey-dark;
        }
    }

    .menu__top-link {
        display: block;
        font-size: 1rem;
        text-decoration: none;
        color: $white-ter;
        border-top: 1px solid transparentize($grey,0.8);
        border-bottom: none;
        margin: 0 0 0 -10px;
        position: relative;
        // font-weight: $weight-semibold;

        @media only screen and (min-width : 768px) {
            display: inline-block;
            margin: 0;
            border-top: none;
            position: static;
            font-size: 1.2rem;
            letter-spacing: -0.1px;
        }

        &.menu__top-link--single-item a:before,
        &.menu__top-link--single-item a:after {
            display: none;
        }
    }

    .menu__top-link > a {
        padding: 1.25rem;
        display: block;

        @media only screen and (min-width : 768px) {
            padding: initial;
            display: inline-block;
            padding: 1rem .5rem;
            border-bottom: 3px solid transparent;
            color: $white-ter;
        }

        &:after,
        &:before {
            content: '';
            position: absolute;
            right: 0;
            background-color: $white-ter;
            width: 3px;
            height: 9px;
            @include transition(all 0.25s ease-in-out);

            @media only screen and (min-width : 768px) {
                display: none;
            }
        }

        &:before {
            transform: translate(-28px, 10px) rotate(45deg);
        }

        &:after {
            transform: translate(-32px, 10px) rotate(-45deg);
        }
    }

    .menu__top-link a:hover,
    .menu__top-link.is-active > a {
        text-decoration: none;

        @media only screen and (min-width : 768px) {
            border-bottom: 3px solid $new-brown;
        }

        &:before,
        &:after {
            background-color: $white-ter;
        }
    }

    .menu__top-link.is-active > a {
        color: $new-brown;

        @media only screen and (min-width : 768px) {
            color: inherit;
        }
    }

    @media only screen and (min-width : 768px) {
        > .menu__top-link:hover > a {
            text-decoration: none;
            border-bottom: 3px solid $new-brown;
        }
    }    
}

/* -- SUB NAV -- */
.sub-menu {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    @include transition(max-height ease-in .4s, opacity ease .6s);

    @media only screen and (min-width : 768px) {
        width: 100%;
        position: absolute;
        left: 0;
        z-index: -1;
        -ms-transform: translateX(-100%);
        transform: translateX(-100%);
        opacity: 0;
        transition: opacity ease-out .4s;
        padding-bottom: 0;
        max-height: 100%;
        overflow: visible;
    }

    .content {
        position: relative;
        top: auto;
        padding: 0;

        @media only screen and (min-width : 768px) {
            background: $white;
            top: auto;
            padding: 3rem 0 3.5rem;
            border-bottom: 2px solid $grey-lighter;
        }
    }

    .container__wrapper {
        margin: 0 auto;

        @media only screen and (min-width : 768px) {
            max-width: 90%;
            width: 90%;
        }

        @media only screen and (min-width : 1000px) {
            max-width: $small-device;
            width: $small-device;
        }

        @media only screen and (min-width : 1192px) {
            max-width: $medium-device;
            width: $medium-device;
        }

        @media only screen and (min-width : 1384px) {
            max-width: $large-device;
            width: $large-device;
        }
    }

    &__spacing-fixer {
        @media only screen and (max-width : 1000px) {
            .column.is-6 {
                &:not(:first-child) {
                    padding-top: 0;
                }

                .menu__bottom-link:last-child {
                    margin-bottom: 0;
                }
            }

            .column.is-6:last-child {
                .menu__bottom-link:last-child {
                    margin-bottom: 3rem;
                }
            }
        }
    }

    .sub-menu__title {
        font-size: 1rem;
        font-family: inherit;
        margin-left: 3.5rem;
        background: none;
        text-align: left;
        width: 100%;
        color: $white;
        text-transform: uppercase;

        @media only screen and (min-width : 768px) {
            font-size: 1.5em;
            font-family: $family-sans-serif;
            font-weight: 300;
            margin-left: 0;
            text-transform: capitalize;
            margin-top: 0;
            padding-bottom: 1rem;
            background-image: linear-gradient(to right, $grey-light 25%, rgba(255, 255, 255, 0) 0%);
            background-position: bottom;
            background-size: 8px 1px;
            background-repeat: repeat-x;
            color: $grey-dark;
            letter-spacing: -0.5px;
        }
    }

    @media only screen and (min-width : 768px) {
        h3.sub-menu__title:not(:first-child) {
            margin-top: 5em;
        }
    }

    .sub-menu__links {
        width: 100%;
        margin: 0 auto 0 -1.5rem;
        font-size: 1rem;
        padding-left: 3rem;

        @media only screen and (min-width : 768px) {
            padding-left: 0;
        }
    }

    .sub-menu__links ul {
        padding: 0;
        list-style: none;
        margin-top: 0;
    }

    .menu__bottom-link {
        margin-top: 1rem;
        margin-bottom: 1rem;

        @media only screen and (min-width : 768px) {
            display: inline-block;
            font-size: 1rem;
            text-align: left;
            margin-top: .5rem;
            margin-bottom: .5rem;
            margin-right: 1rem;
            padding: 0;
            width: 45%;
            float: left;
            line-height: 1.3;

            a {
                color: $grey;
            }
        }

        &.is-active a {
            color: $new-brown;
            text-decoration: none;
        }
    }

    .menu__bottom-link:first-child {
        margin-top: 0;

        @media only screen and (min-width : 768px) {
            margin-top: 0.5rem;
        }
    }

    .menu__bottom-link:last-child {
        margin-bottom: 3rem;

        @media only screen and (min-width : 768px) {
            margin-bottom: 0.5rem;
        }
    }

    .sub-menu__links .menu__bottom-link a:hover,
    .sub-menu__links .menu__bottom-link a:focus {
        color: $new-brown;
        border-bottom: 1px solid $new-brown;
    }

    .menu-fields__media {
        position: relative;

        .image__menu {
            position: relative;
            overflow: hidden;

            &:before {
                display: block;
                content: " ";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: url(../../img/vGrad.png) repeat-x bottom;
                opacity: 0;
                @include transition(opacity .25s ease);
            }
        }
    }

    .menu-fields__caption {
        color: $white-ter;
        position: absolute;
        bottom: .5rem;
        left: 1.25rem;
    }

    .menu-fields__media:hover {
        .image__menu:before,
        .menu-fields__caption {
            opacity: 1;
        }

        .menu-fields__caption {
            transform: translateY(0);
        }
    }

    .image__menu {
        height: 200px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center 30%;
        -webkit-filter: grayscale(100%);
        -moz-filter:    grayscale(100%);
        -ms-filter:     grayscale(100%);
        -o-filter:      grayscale(100%);
        filter: gray;
    }
}

// sub-menu dropdown toggled
.dropdown-open {
    .sub-menu {
        max-height: 1000px;
        opacity: 1;
    }

    &.menu__top-link > a {
        &:before {
            transform: translate(-32px, 10px) rotate(45deg);
        }

        &:after {
            transform: translate(-28px, 10px) rotate(-45deg);
        }
    }
}

// top nav toggled
.top-nav.is-active {
    top: 0;
    bottom: 0;
    position: fixed;
    overflow-y: scroll;
    width: 100%;
    z-index: 100;
    margin-top: 72px;

    @media only screen and (min-width : 390px) {
        margin-top: 80px;
    }

    @media only screen and (min-width : 480px) {
        margin-top: 104px;
    }

    .menu {
        max-height: 1500px;
        opacity: 1;
    }
}

@media only screen and (min-width : 768px) {
    // both menus toggled
    .menu li > a:focus+.sub-menu,
    .menu li > a:hover+.sub-menu,
    .sub-menu:focus,
    .sub-menu:hover {
        -ms-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
        z-index: 100;
    }
}