//--------------------------------------------------
// BOX, CARD & ARTICLE
//--------------------------------------------------
/* -- BOX & CARD -- */
.box,
.card {
    @include border-radius(2px);
}

.box {
    @include border-radius(2px);
    padding: 1.75rem 2.25rem;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

a.box {
    @include transition(box-shadow .3s cubic-bezier(.165,.84,.44,1));
}

a.box:hover,
a.box:focus {
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0), 0 0 0 1px rgba(10, 10, 10, 0.1);

    @media only screen and (min-width : 768px) {
        box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px $grey;
    }
}

.card {
    box-shadow: none;
    background-color: transparentize($success,0.95);
}

/* -- ARTICLE -- */
// Read more
.read-more {
    text-transform: uppercase;
    margin: 1rem 0 0;
    display: block;
    color: $success;

    &:after {
        content: '\27F6';
        margin-left: 0.25rem;
        @include transition(margin .25s cubic-bezier(0.39, 0.575, 0.44, 1));
    }
}

.photo__gallery__wrapper .card:hover .read-more,
a.read-more:hover,
.read-more:hover {
    color: $success;

    &:after {
        margin-left: 0.5rem;
    }
}

// Article header
.card-header {
    box-shadow: none;
}

.article-header-date,
.card-content {
    padding: 1.25rem 2rem;
}

// Article content
.card-content {
    padding: 1.5rem 2rem;
}

.article .card-image .image {
    background-repeat: no-repeat;
    background-size: cover;
}

.article h4,
.article h4 a,
.article h6,
.article h6 a {
    color: $new-brown;
    border-bottom: 1px dotted transparent;
    @include transition(border .3s cubic-bezier(.165,.84,.44,1), color .25s cubic-bezier(0.39, 0.575, 0.44, 1));

    &:hover {
        color: lighten($primary,10%);
    }
}

.news__article-excerpt {
    background-color: transparentize($success,0.95);
    padding: 1.25rem 1.5rem 1rem;

    &:not(:last-child) {
        border-bottom: 2px solid $white;
    }

    h6,
    h6 a {
        color: $grey-darker;
        border-bottom: 1px dotted transparent;
        @include transition(border .3s cubic-bezier(.165,.84,.44,1), color .25s cubic-bezier(0.39, 0.575, 0.44, 1));

        &:hover {
            color: lighten($primary,10%);
        }
    }
}

.article:hover a,
.news__article-excerpt:hover a {
    @media only screen and (min-width : 768px) {
        border-bottom-color: $grey-light;
    }
}

.news__article-excerpt__date {
    font-family: $secondary-font-family;
    font-weight: $weight-light;
    font-size: 70%;
    text-transform: uppercase;

    &:before {
        content: '\2014';
    }
}

// News article tag list
.news__article__tag-list {
    margin-top: 0.25rem;
}

// Article footer
.article-footer__meta {
    position: relative;
    
    .tag {
        position: absolute;
        right: 0;
        bottom: -3px;
    }
}

/* -- CURRENT CAMPAIGNS BOX -- */
.campaigns-box {
    .subtitle {
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: $weight-bold;
        text-align: center;
    }

    .image img {
        margin: 0 auto;
    }
}

/* -- MEDIA-- */
.gallery__wrapper {
    .card-content {
        padding: 1.5rem 1.25rem;
    }

    h6 {
        display: inline;
        border-bottom: 1px dotted transparent;
        @include transition(border .3s cubic-bezier(.165,.84,.44,1), color .25s cubic-bezier(0.39, 0.575, 0.44, 1));

        &:hover {
            color: lighten($primary,10%);
        }
    }

    .content h6 {
        margin-bottom: 0;
    }

    .card:hover h6 {
        @media only screen and (min-width : 768px) {
            border-bottom-color: $grey-light;
        }
    }
}

.photo__gallery__wrapper .card {
    .card-image {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: transparentize($new-brown,.3);
            z-index: 1;
            opacity: 0;
            @include transition(opacity .2s ease-in);
        }

        &:after {
            content: 'VIEW GALLERY';
            @include contentcenter(true, true);
            z-index: 2;
            color: $white;
            font-weight: $weight-bold;
            font-size: .9rem;
            opacity: 0;
            @include transition(opacity .2s ease-in .2s);
        }
    }

    &:hover {
        .card-image:before,
        .card-image:after {
            opacity: 1;
            cursor: pointer;
        }
    }
}