//--------------------------------------------------
// OTHER CUSTOMIZATIONS
//--------------------------------------------------
Highlight Color Customization
::-moz-selection {
    color: white;
    text-shadow: none;
    background: $success;
}

::selection {
    color: white;
    text-shadow: none;
    background: $success;
}

img::selection {
    color: white;
    background: transparent;
}

img::-moz-selection {
    color: white;
    background: transparent;
}

body {
    webkit-tap-highlight-color: $success;
}

:focus {
    outline-color: $success;
}