//--------------------------------------------------
// FORMS
//--------------------------------------------------
.input,
.textarea {
    box-shadow: none;
}

.input,
.textarea,
.select select {
    &:focus {
        outline: 3px solid $primary;
        outline-offset: 2px;
    }

    &:focus,
    &.is-focused,
    &:active,
    &.is-active {
        border-color: $grey-light;
    }
}