//--------------------------------------------------
// CUSTOM ICONS
//--------------------------------------------------
/* -- GRUNT ICONS -- */
.mm-icon {
    display: block;
    font-size: 1px;

    &.mm-icon-inline {
        display: inline-block;
    }
}

.mm-icon-gray {
    svg {
        stroke: $grey-light;

        path {
            stroke: $grey-light;
        }
    }
}

.mm-icon-blue {
    svg {
        stroke: $picton-blue;
    }
}

.mm-icon-green {
    svg {
        stroke: $pacific-blue;
    }
}

.mm-icon-red {
    svg {
        stroke: $cornell-red;
    }
}

.mm-icon-xs,
.mm-icon-xs svg {
    width: 14px;
    height: 14px;
    margin-right: .25rem;
}

.mm-icon-md,
.mm-icon-md svg {
    width: 32px;
    height: 32px;
}

.mm-icon-lg,
.mm-icon-lg svg {
    width: 48px;
    height: 48px;
}