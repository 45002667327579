//--------------------------------------------------
// MAIN SCAFFOLDING
//--------------------------------------------------
html,
body {
    background-color: $white-ter;

    &.menu-open {
        overflow: hidden;
    }
}

body {
    margin-top: 70px;

    @media only screen and (min-width : 480px) {
        margin-top: 91px;
    }

    @media only screen and (min-width : 768px) {
        margin-top: auto;
    }
}

/* -- CONTAINER -- */
.container {
    @media only screen and (min-width : 1024px) {
        max-width: $small-device;
        width: $small-device;
        // padding-left: 25px;
        // padding-right: 25px;
    }

    @media only screen and (min-width : 1216px) {
        max-width: $medium-device;
        width: $medium-device;
    }

    @media only screen and (min-width : 1408px) {
        max-width: $large-device;
        width: $large-device;
    }

    @media only screen and (min-width : 1590px) {
        max-width: $extra-large-device;
        width: $extra-large-device;
    }
}

.section {

    &.section-no-padd-top {
        padding-top: 0;
    }

    &.section-no-padd-bottom {
        padding-bottom: 0;
    }

    &.page-content {
        background-color: $white;
    }
}

.section-container-wrapper__has-sidebar {
    background-color: $white;
}

.page-content__wrapper {
    .section {
        padding-left: 0;
        padding-right: 0;

        &:first-child {
            padding-top: 0;
        }
    }
}

/* -- CONTENT PLACEHOLDERS -- */
@-webkit-keyframes placeholder-shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 968px 0;
  }
}

@keyframes placeholder-shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 968px 0;
  }
}

@-webkit-keyframes content-placeholder-dot-a {
  8% {
    -webkit-transform: translateY(0);
  }
  22% {
    -webkit-transform: translateY(-0.3125rem);
  }
  36% {
    -webkit-transform: translateY(0);
  }
}

@keyframes content-placeholder-dot-a {
  8% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  22% {
    -webkit-transform: translateY(-0.3125rem);
    transform: translateY(-0.3125rem);
  }
  36% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes content-placeholder-dot-b {
  36% {
    -webkit-transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-0.4375rem);
  }
  64% {
    -webkit-transform: translateY(0);
  }
}

@keyframes content-placeholder-dot-b {
  36% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-0.4375rem);
    transform: translateY(-0.4375rem);
  }
  64% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes content-placeholder-dot-c {
  64% {
    -webkit-transform: translateY(0);
  }
  78% {
    -webkit-transform: translateY(-0.3125rem);
  }
  92% {
    -webkit-transform: translateY(0);
  }
}

@keyframes content-placeholder-dot-c {
  64% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  78% {
    -webkit-transform: translateY(-0.3125rem);
    transform: translateY(-0.3125rem);
  }
  92% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.content-placeholder-container {
  position: relative;
  width: 100%;
  height: 9.5rem;
  border: none;
}

.content-placeholder-media, .content-placeholder-media:before, .content-placeholder-media:after, .content-placeholder-heading, .content-placeholder-heading:before, .content-placeholder-heading:after, .content-placeholder-text, .content-placeholder-text:before, .content-placeholder-text:after {
  position: absolute;
  background: #dbdbdb;
  background-image: linear-gradient(to right, #dbdbdb 0%, #edeef1 20%, #dbdbdb 40%, #dbdbdb 100%);
  background-repeat: no-repeat;
  background-size: 800px 200px;
  animation: placeholder-shimmer 1s linear infinite;
}

.content-placeholder-media {
  top: 0.5rem;
  left: 0.5rem;
  width: 4rem;
  height: 4rem;
}

.content-placeholder-media:before, .content-placeholder-media:after {
  top: 2rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 1rem;
  background: #7a7a7a;
  content: '';
}

.content-placeholder-media:before {
  left: 0.75rem;
  -webkit-animation: content-placeholder-dot-a 1.8s ease infinite;
  animation: content-placeholder-dot-a 1.8s ease infinite;
}

.content-placeholder-media:after {
  left: 1.75rem;
  -webkit-animation: content-placeholder-dot-b 1.8s ease infinite;
  animation: content-placeholder-dot-b 1.8s ease infinite;
}

.content-placeholder-heading {
  top: 1.125rem;
  left: 5rem;
  width: calc(100% - 5.5rem);
}

.content-placeholder-heading, .content-placeholder-heading:before {
  height: 0.875rem;
}

.content-placeholder-heading:before, .content-placeholder-heading:after {
  content: '';
}

.content-placeholder-heading:before {
  top: 1.5rem;
  width: 85%;
}

.content-placeholder-heading:after {
  top: 1.375rem;
  left: -1.75rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 1rem;
  background: #7a7a7a;
  -webkit-animation: content-placeholder-dot-c 1.8s ease infinite;
  animation: content-placeholder-dot-c 1.8s ease infinite;
}

.content-placeholder-text {
  top: 5.25rem;
  left: 0.5rem;
  width: calc(85% - 1rem);
}

.content-placeholder-text, .content-placeholder-text:before, .content-placeholder-text:after {
  height: 0.75rem;
}

.content-placeholder-text:before, .content-placeholder-text:after {
  content: '';
}

.content-placeholder-text:before {
  top: 1.25rem;
  width: calc(120% - 1rem);
}

.content-placeholder-text:after {
  top: 2.5rem;
  width: 90%;
}

#content-placeholder {
    display: none;
}

.content-placeholder-container--single {

    .content-placeholder-heading {
        top: 0;
        left: .5rem;

        &:after {
            display: none;
        }
    }
}

.content-placeholder-text--single {
    top: 0;
}