//--------------------------------------------------
// PEOPLE LISTINGS
//--------------------------------------------------
/* -- GENERAL -- */
.people-listing__wrapper {
    .box {
        height: 100%;
        box-shadow: none;
    }

    .subtitle {
        font-size: 1rem;
    }

    .image img {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        filter: gray;
        // filter: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' height='0'><filter id='greyscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0' /></filter></svg>#greyscale");
    }
}