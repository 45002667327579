//--------------------------------------------------
// GENERAL PAGE
//--------------------------------------------------
/* -- PAGE LOADER -- */
#page-load__overlay {
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 1030;
    display: block;

    &.overlay-hidden {
        display: none;
    }
}

#nprogress {
    pointer-events: none;
}

#nprogress .bar {
    background: $success;
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
}

#nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px $success, 0 0 5px $success;
    opacity: 1.0;
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
}

#nprogress .spinner {
    display: block;
    z-index: 1031;
    @include contentcenter(true, true);
    // position: fixed;
    // top: 15px;
    // right: 15px;
}

#nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border: solid 2px transparent;
    border-top-color: $success;
    border-left-color: $success;
    border-radius: 50%;
    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
    overflow: hidden;
    position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
    position: absolute;
}

@-webkit-keyframes nprogress-spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes nprogress-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* -- PAGE BREADCRUMBS -- */
.breadcrumb {
    font-size: 0.9rem;
    padding: 1rem 1.5rem;
}

.breadcrumb__listing {
    color: $grey;

    a {
        color: $grey;
        @extend %link-animation;
    }

    @media only screen and (min-width : 768px) {
        a:hover,
        a:focus {
            color: $grey-darker;
            border-bottom: 1px solid $grey-darker;
        }
    }

    li:not(:last-child):after {
        content: '>';
        color: $grey-light;
        padding: 0 0 0 0.5rem;

        @media only screen and (min-width : 768px) {
            padding: 0 0.25rem 0 0.75rem;
        }
    }

    li:first-child:after {
        display: none;
    }

    li.is-active {
        color: $grey-dark;
        background-image: linear-gradient(to left, $new-brown 25%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size: 4px 1px;
        background-repeat: repeat-x;
    }
}

/* -- PAGE HEADER -- */
.page-header {
    .hero-body {
        padding: 2rem 1.5rem;
        background: rgba(220,225,229,1);
        background: -moz-linear-gradient(-45deg, rgba(220,225,229,1) 0%, rgba(123,151,170,0.43) 87%, rgba(123,151,170,0.35) 100%);
        background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(220,225,229,1)), color-stop(87%, rgba(123,151,170,0.43)), color-stop(100%, rgba(123,151,170,0.35)));
        background: -webkit-linear-gradient(-45deg, rgba(220,225,229,1) 0%, rgba(123,151,170,0.43) 87%, rgba(123,151,170,0.35) 100%);
        background: -o-linear-gradient(-45deg, rgba(220,225,229,1) 0%, rgba(123,151,170,0.43) 87%, rgba(123,151,170,0.35) 100%);
        background: -ms-linear-gradient(-45deg, rgba(220,225,229,1) 0%, rgba(123,151,170,0.43) 87%, rgba(123,151,170,0.35) 100%);
        background: linear-gradient(135deg, rgba(220,225,229,1) 0%, rgba(123,151,170,0.43) 87%, rgba(123,151,170,0.35) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dce1e5', endColorstr='#7b97aa', GradientType=1 );
    }

    .page-header__title {
        margin-bottom: 1rem;
        margin-top: 1rem;
        text-shadow: 0 1px 2px rgba(10, 10, 10, 0.1);
        position: relative;
        padding-top: 2rem;

        &:before {
            content: "";
            width: 70px;
            height: 6px;
            position: absolute;
            top: 5px;
            left: 0;
            background-color: $new-brown;
        }
    }

    .page-header__title.page-header__title--standalone {
        border-bottom: 1px solid $white-ter;
        padding-bottom: 1.5rem;
        margin-bottom: 2rem;
        margin-top: 2rem;

        &:before {
            content: "";
            width: 70px;
            height: 6px;
            position: absolute;
            top: 5px;
            left: 0;
            background-color: $new-brown;
        }
    }

    .page-header__subtitle {
        text-transform: uppercase;
        font-family: $family-sans-serif;
        font-weight: $weight-semibold;
        font-size: 1rem;
        letter-spacing: 1.05px;
        text-shadow: 0 1px 2px rgba(10, 10, 10, 0.1);
        margin-bottom: .5rem;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid $white-ter;
    }
}

/* -- SECTION TITLES -- */
h3.page-section__title {
    font-family: $secondary-font-family;
    font-weight: $secondary-font-weight-bold;
    letter-spacing: 0.8px;
}

.content .page-section__title,
.page-section__title {
    margin-bottom: 1.5rem;

    &.page-section__title-bottom-flush {
        margin-bottom: 0rem;
    }
}

.page-section__title {
    position: relative;
    padding-top: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $grey-lighter;

    &--no-underline {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 1.5rem;
    }

    &:before {
        content: "";
        width: 70px;
        height: 6px;
        position: absolute;
        top: 5px;
        left: 0;
        background-color: $new-brown;
    }
}

.page-section__subtitle {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;

    &:after {
        content: "";
        display: block;
        width: 25px;
        padding-top: 0.2rem;
        border-bottom: 4px solid $info;
    }

    .article-count {
    }
}

/* -- PAGE META / SOCIAL -- */
.page-meta {
    background-color: $white;

    p {
        color: $grey-light;
    }

    .sharer {
        .level-item {
            display: inline-block;
        }
    }
}

/* -- SECTION CONTENTS -- */
// Listing of articles, speeches etc
.prev-cat-statements__wrapper {
    @media only screen and (min-width : 768px) {
        margin-top: 2rem;
    }
}

.more-recent-updates,
.news-listing__wrapper,
.news__article-excerpt {
    h6 {
        margin-bottom: 0;
    }
}

.attachment-box {
    padding: 0;
    
    .message {
        background-color: transparent;
    }

    .message-body {
        padding: 2em 2.25em;
    }
}

/* -- CONTACT PAGE -- */
.map__wrapper {
    padding: 0;
}

#map {
    height: 400px;
    width: 100%;
}

/* -- CAMPAIGNS -- */
.pg-image-gallery__wrapper {
    padding-top: 0;
}

/* -- LATEST UPDATES -- */
.update-results__wrapper {
    padding-top: 0;
}

.update-results-box {
    overflow: hidden;
}

.update-results {
    @include transition(transform .3s cubic-bezier(0.17, 0.04, 0.03, 0.94), opacity .35s ease);
    transform: translateY(-250px);
    opacity: 0;
}

.update-results-box.is-displayed {

    .update-results {
        transform: translateY(0);
        opacity: 1;
    }
}