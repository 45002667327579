//--------------------------------------------------
// MAIN SCAFFOLDING
//--------------------------------------------------
.is-fullheight {
    height: 100%;
}

.field.field-centered {
    justify-content: center;
}

.control__inline {
    display: inline-block;

    .button {
        margin-bottom: 0.25rem;
    }
}