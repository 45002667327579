//--------------------------------------------------
// MODALS
//--------------------------------------------------
.modal {
    z-index: 120;

    p {
        color: $white-ter;
    }
}

.modal-close {
    max-width: 100%;
    max-height: 100%;

    &.is-large {
        height: 60px;
        width: 60px;
    }
}