//--------------------------------------------------
// BUTTONS
//--------------------------------------------------
/* -- GENERAL -- */
.button {
    @include transition(background .2s ease-in);
    font-family: $secondary-font-family;

    &:hover {
        -webkit-box-shadow: inset 0 -2px 0 rgba(31,45,61,.15);
                box-shadow: inset 0 -2px 0 rgba(31,45,61,.15);
    }
}